import React, { useRef, useState } from 'react';
import './SideMenu.css';
import ResumeFooter from './ResumeFooter';
import { Nav } from 'react-bootstrap';
import placeHolder from '../../../Assets/Images/dummy.png'

const SideMenu = (props) => {

    const responseData = props.SideMenuRespData;

    //Handler 
    const sideNavLinksActiveHandler = () => {
        const sideNavLinks = document.querySelectorAll('.sideMenu-links-main');
        const sideNavSections = document.querySelectorAll('.sideMenu-links-section');
        let current = '';

        sideNavSections.forEach(section => {
            const sectionTop = section.offsetTop - 10;
            if (window.pageYOffset >= sectionTop) {
                current = section.getAttribute('id');
            }
        });
        sideNavLinks.forEach((navlink) => {
            navlink.children[0].classList.remove('resume-sidenav-active');
            navlink.classList.remove('resume-sidemenu-active');

            if (navlink.classList.contains(current)) {
                navlink.children[0].classList.add('resume-sidenav-active');
                navlink.classList.add('resume-sidemenu-active');
            }
        })

    }
    window.addEventListener('scroll', sideNavLinksActiveHandler);

    const sideNavbarOverlay = useRef(null);
    const openSidebar = () => {
        sideNavbarOverlay.current.style.transform = 'translateX(0%)';
    }
    const closeSideBar = () => {
        sideNavbarOverlay.current.style.transform = 'translateX(-150%)';
        props.CloseSidebarHandler();
    }

    //Navbar open-button Click Handler using props
    props.OpenSideBarHandler && openSidebar();


    const scrollIntoViewHandler = (id) => {

        if (window.matchMedia('(max-width: 1024px)').matches) {
            closeSideBar();
        }

        const anchor = document.getElementById(id);
        const yOffset = -500;
        const y = anchor.getBoundingClientRect().top + window.pageYOffset + yOffset; anchor.scrollIntoView({ behavior: 'smooth', top: y })
    }
    const [replaceAbleImage, setReplaceAbleImage] = useState('');

    //When the Enity Image is not present we call the following statement to replace the empty space with a dumy image.
    const onErrorHandler = () => {
        if (responseData.image.data) {
            setReplaceAbleImage(`${process.env.REACT_APP_BASE_URL}${responseData.image.data.url}`);
        } else {
            setReplaceAbleImage(placeHolder);
        }
    }
    !(replaceAbleImage) && onErrorHandler();

    return (
        <section className='header position-fixed d-flex align-items-center' ref={sideNavbarOverlay}>
            <header className='header-btn position-fixed'>
                <button className='bg-transparent d-xl-none px-2' onClick={closeSideBar}><i className='fas fa-times'></i></button>
            </header>
            <div className="card-side text-white mb-3">
                <div className="card-side-header d-flex flex-column">
                    <div className='profile-img-side m-auto my-3' style={{ backgroundImage: `url(${replaceAbleImage})` }}></div>
                    <h3 className='m-auto'>{responseData.name}</h3>
                    <p>{responseData.title}</p>
                </div>
                <div className="card-side-body">
                    <nav id='side-navbar' className='navbar'>
                        <ul className='position relative w-100 d-flex flex-column justify-content-center list-unstyled'>
                            <li>
                                <Nav.Link className='sideMenu-links-main resume-home d-flex align-items-center' onClick={() => scrollIntoViewHandler('resume-home')}>
                                    <div className='resume-sidenav'></div>
                                    <div className=' ms-2'>
                                        <i className='fas fa-home'></i>
                                        <span className='ms-2'> Home </span>
                                    </div>
                                </Nav.Link>
                            </li>
                            <li>
                                <Nav.Link className='sideMenu-links-main resume-about d-flex align-items-center' onClick={() => scrollIntoViewHandler('resume-about')}>
                                    <div className='resume-sidenav '></div>
                                    <div className=' ms-2'>
                                        <i className='fas fa-user'></i>
                                        <span className='ms-2'> About </span>
                                    </div>

                                </Nav.Link>
                            </li>
                            <li>
                                <Nav.Link className='sideMenu-links-main resume-nav d-flex align-items-center' onClick={() => scrollIntoViewHandler('resume-nav')}>
                                    <div className='resume-sidenav '></div>
                                    <div className=' ms-2'>
                                        <i className='fas fa-sticky-note'></i>
                                        <span className='ms-2'>Resume </span>
                                    </div>

                                </Nav.Link>
                            </li>
                            <li>
                                <Nav.Link className='sideMenu-links-main resume-contact d-flex align-items-center' onClick={() => scrollIntoViewHandler('resume-contact')}>
                                    <div className='resume-sidenav '></div>
                                    <div className=' ms-2'>
                                        <i className='fas fa-envelope'></i>
                                        <span className='ms-2'> Contact </span>
                                    </div>

                                </Nav.Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className='card-body-footer'>
                    <ResumeFooter />
                </div>
            </div>
        </section>
    )
}

export default SideMenu
