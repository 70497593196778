import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Col, Container, Row} from 'react-bootstrap';
import CaseStudyFooter from './CaseStudyFooter';
import DOMPurify from 'dompurify';
import './CaseStudy.css';
import {useNavigate, useParams} from 'react-router';
import HUBEX_APP_API from '../Services/API';
import HUBEX_APP_ROUTES from '../Services/AppRoutes';
import ReactMarkdown from 'react-markdown';

const CaseStudy = () => {

    // API Response Data start
    const [caseStudyData, setcaseStudyData] = useState('');

    let { projectId } = useParams();
    let navigate = useNavigate()

    useEffect(() => {
        const fecthingData = () => {
            const baseURL = `${process.env.REACT_APP_BASE_URL}${HUBEX_APP_API.CASE_STUDY_API}${projectId}${HUBEX_APP_API.POPULATE_ALL}`;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.REACT_APP_AUTH_KEY;
            axios.get(baseURL)
                .then(response => {
                    setcaseStudyData(response.data.data[0])
                })
                .catch((err) => {
                    console.log("could not find the response:", err)
                    navigate(HUBEX_APP_ROUTES.Error_PAGE)
                })
        }
        fecthingData();

    }, [projectId, navigate])


    if (!caseStudyData) return null

    // API Response Data end

    const resultHtml = DOMPurify.sanitize(caseStudyData.result);
    const coreFunctionalityHtmlList = DOMPurify.sanitize(caseStudyData.core_functionality);

    return (
        <>
            <section>
                <Container>
                    <div>
                        {caseStudyData.content !== '' && (
                            <Row className='case_study_header d-flex justify-content-between lh-m'>
                                <h1 className="fw-bolder display-2 my-5 mb-md-4">{caseStudyData.title}</h1>
                                <Col md={6} className="d-flex align-items-center">
                                    <div className="fs-4">
                                        <p>
                                            <ReactMarkdown>
                                                {caseStudyData.content}
                                            </ReactMarkdown>
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6} className="d-flex justify-content-end align-items-end">
                                    <img className='proj_main_img' alt='img' width="100%" src={`${process.env.REACT_APP_BASE_URL}${caseStudyData.cover_image.data.url}`} />
                                </Col>
                            </Row>
                        )
                        }
                    </div>

                    <div>
                        {caseStudyData.description !== '' && (
                            <div className="py-5">
                                <div className="d-flex">
                                    <div>
                                        <h1 className="fw-bold mb-5">Description:</h1>
                                        <p className='fw-light fs-5'>
                                            <ReactMarkdown>
                                                {caseStudyData.description}
                                            </ReactMarkdown>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                    <div>
                        {caseStudyData.main_goals !== '' && (
                            <div className="py-5">
                                <div className="d-flex">
                                    <div>
                                        <h1 className="fw-bold mb-5">Main Goals:</h1>
                                        <p className='fw-light fs-5'>
                                            <ReactMarkdown>
                                                {caseStudyData.main_goals}
                                            </ReactMarkdown>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                    <div>
                        {caseStudyData.tech_challenge !== '' && (
                            <Row className="py-5">
                                <Col lg={6} className="d-flex">
                                    <div>
                                        <h1 className="fw-bold mb-5">Tech Challenge:</h1>
                                        <p className='fw-light fs-5'>
                                            <ReactMarkdown>
                                                {caseStudyData.tech_challenge}
                                            </ReactMarkdown>
                                        </p>
                                    </div>
                                </Col>
                                <Col lg={6} className="d-flex justify-content-end align-items-end">
                                    <img className='' src={`${process.env.REACT_APP_BASE_URL}${caseStudyData.header_image.data.url}`} width='100%' alt='' />
                                </Col>
                            </Row>
                        )
                        }
                    </div>
                    <div>
                        {caseStudyData.problem_intro !== '' && (
                            <div className="py-5">
                                <div className="d-flex">
                                    <div>
                                        <h1 className="fw-bold mb-5">Problem Introduction:</h1>
                                        <p className='fw-light fs-5'>
                                            <ReactMarkdown>
                                                {caseStudyData.problem_intro}
                                            </ReactMarkdown>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                </Container>
            </section>
            {caseStudyData.core_functionality !== '' && caseStudyData.functionality_images.data !== null && (
                <section className='cs_cf'>
                    <Container>
                        <Row className='lh-lg p-0 py-md-5 justify-content-between'>
                            <Col lg={6} className='cs-sticky text-white'>
                                <h1 className="fw-bold mb-5">Core Functionality:</h1>
                                <p className='fw-light fs-5'>
                                    <ReactMarkdown>
                                        {coreFunctionalityHtmlList}
                                    </ReactMarkdown>
                                </p>
                            </Col>
                            <Col lg={5} className='d-flex flex-column align-items-md-end justify-content-md-end'>
                                {
                                    caseStudyData.functionality_images.data.map((curr, index) => {
                                        return <img src={`${process.env.REACT_APP_BASE_URL}${curr.url}`} key={index} className="my-4" alt='scrolling-img' width='100%' />
                                    })

                                }
                                )
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
            }
            <Container>

                {
                    caseStudyData.result === null ?
                        <Row className='lh-lg pb-5'>
                            <Col lg={12}>
                                <h1 className="fw-bold my-5">Solution</h1>
                                <div className='fw-light'>
                                    <p className='fw-light fs-5'>
                                        <ReactMarkdown>
                                            {caseStudyData.solution}
                                        </ReactMarkdown>
                                    </p>

                                </div>
                            </Col>
                        </Row>
                        :
                        <Row className='lh-lg pb-5'>
                            <Col lg={7}>
                                <h1 className="fw-bold my-5">Solution</h1>
                                <div className='fw-light'>
                                    <p className='fw-light fs-5'>
                                        <ReactMarkdown>
                                            {caseStudyData.solution}
                                        </ReactMarkdown>
                                    </p>

                                </div>
                            </Col>
                            <Col lg={5} className='cs_result_section'>
                                <h1 className="fw-bold ms-2 my-5">Customer Benifits</h1>
                                <p className='fw-light fs-5'>
                                    <ReactMarkdown>
                                        {resultHtml}
                                    </ReactMarkdown>
                                </p>
                            </Col>
                        </Row>
                }

            </Container>
            <CaseStudyFooter />
        </>
    );
}
export default CaseStudy;