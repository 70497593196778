import React from 'react';
import { Col } from 'react-bootstrap';
import './PersonalResume.css';
import DOMPurify from 'dompurify'

//Projects Container Component
const ProjectContainer = (props) => {

    const rawHTML = DOMPurify.sanitize(props.descHTML);

    return (
        <Col md={6}>
            <div className='resume-border'>
                <div className='fs-4 fw-bolder mb-2'>{props.title}</div>
                <div className='pl-5'>
                    {<div dangerouslySetInnerHTML={{ __html: rawHTML }} />}
                </div>
            </div>
        </Col>
    );
}
export default ProjectContainer;
