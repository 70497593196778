import React from "react"
import {Col, Row} from "react-bootstrap";
import ReactPlayer from "react-player";

const Header = () => {

    return (
        <>
            <div className="d-md-block d-none">
                <ReactPlayer width='100%' height='100vh' controls url='https://youtu.be/rBDdmA95c1Y'
                             sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-storage-access-by-user-activation allow-presentation"
                />
            </div>
            <div className="d-block d-md-none">
                <ReactPlayer width='100%' height='300px' controls url='https://youtu.be/rBDdmA95c1Y'
                             sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-storage-access-by-user-activation allow-presentation"
                />
            </div>
            <Row className="text-center d-flex justify-content-between overflow-main w-100 m-0 p-0">
                <Col xl={6}
                     className="d-flex flex-column justify-content-center align-items-center text-center mt-5 mt-md-0">
                    <h1 className="fw-bold">
                        Place of ROR experts,<br/> We are here to assist your ROR projects
                    </h1>
                    <h5 className="my-5 h4 lh-base">
                        Why don't you take the risk-free, no-obligation offer by signing up at Hubex
                        Tech,
                        where we design, develop, and scale up the idea by leveraging trending
                        technologies? <br/>
                        Reach us by filling up the form and we will send you access to the <span
                        className="fw-bold m-0">ROR Project Cost
                        Estimation Calculator</span> to estimate your project by yourself.
                    </h5>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                    <iframe
                        src="https://finalrorhubextech.gr8.com/"
                        title="Landing Page Contact Form"
                        className="w-100"
                        scrolling="no"
                        height="605"
                        allowFullScreen={true}
                    ></iframe>
                </Col>
            </Row>

        </>
    )
}

export default Header