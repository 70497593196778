import React, {useEffect, useRef, useState} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import HubexLogo from '../../Assets/Images/logo.png'
import EButton from '../UI/Button';
import {useNavigate} from "react-router-dom"
//Custom Stylesheet
import './MainNavbar.css';
//Font Awesome Icons
import HUBEX_APP_ROUTES from '../../Components/Services/AppRoutes'

const MainNavbar = ({flag}) => {

    let navigate = useNavigate();
    //Handler 
    const navlinksActiveHandler = () => {
        let navlinks = document.querySelectorAll('.nav-links-main');
        let sections = document.querySelectorAll('.navlinks-section')
        let current = '';


        sections.forEach(section => {

            const sectionTop = section.offsetTop - 100;
            if (window.pageYOffset >= sectionTop) {
                current = section.getAttribute('id');
            }

        });

        navlinks.forEach((navlink) => {
            navlink.classList.remove('active-main');
            if (navlink.classList.contains(current)) {
                navlink.classList.add('active-main')

            }
        })
    }
    window.addEventListener('scroll', navlinksActiveHandler);
    const sideOverlay = useRef(null);
    const openSidebar = () => {
        sideOverlay.current.style.transform = 'translateX(0%)';
    }
    const closeSideBar = () => {
        sideOverlay.current.style.transform = 'translateX(-100%)';
    }
    //Sticky Navbar]
    const handleScroll = () => {
        if (window.scrollY > 500) {
            setNavClasses('navbar-main position-fixed top-0 nav-bg w-100')
        } else {
            setNavClasses('navbar-main')
        }
    }

    const handleDropdown = () => {
        setOpen(!open)
    }

    const scrollIntoViewHandler = (id) => {
        //This closeSideBar function is just for mobile devices
        //It will help us when a user is on mobile device and open the sidebar
        //and then click on clicks then the navbar will be close and scroll to the desired section.
        closeSideBar();
        const anchor = document.getElementById(id);
        const yOffset = -500;
        const y = anchor.getBoundingClientRect().top + window.pageYOffset + yOffset;
        anchor.scrollIntoView({behavior: "smooth", top: y})
    }
    const [navClasses, setNavClasses] = useState('py-4 navbar-main');
    window.addEventListener('scroll', handleScroll)
    const [open, setOpen] = useState(false);
    const ref = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref, open]);

    return (
        <div className='sticky' style={{zIndex: 999}}>
            <Navbar expand="lg" className={flag && flag === "noNavBar" ? `bg-white` : navClasses}>
                {
                    flag && flag === "noNavBar" ? <Container className="overflow-nav">
                        <div className="w-50">
                            <a href="https://www.hubextech.com/">
                                <img src={HubexLogo} className="img-fluid" width="180px" alt="logo"/></a>
                        </div>
                        <div>
                            <a href="https://calendly.com/moeez-haider-hubextech/hubextech"
                               className="book-slot bg-transparent fs-4 text-decoration-none">
                                Book your time
                            </a>
                        </div>
                    </Container> : <Container>
                        <div className="w-50">
                            <a href="https://www.hubextech.com/">
                                <img src={HubexLogo} className="img-fluid" width="180px" alt="logo"/></a>
                        </div>
                        <button className="bg-transparent border-0 nav-menu-btn d-lg-none" onClick={openSidebar}>
                            <i className="fas fa-bars nav-menu_icon fs-3"></i>
                        </button>
                        <Navbar.Toggle className="d-none"/>
                        <Navbar.Collapse className="justify-content-end" id="navbarScroll">
                            <Nav
                                className="mr-auto my-2 my-lg-0"
                                style={{maxHeight: '100px'}}
                                navbarScroll
                            >
                                <Nav.Link className="nav-links-main homeNav d-flex align-items-center"
                                          onClick={() => scrollIntoViewHandler('homeNav')}>Home</Nav.Link>
                                <div className="dropdown-main">
                                    <div className="dropdown" ref={ref}>
                                        <button className="dropbtn" onClick={handleDropdown}>Services
                                            <i className="fa fa-caret-down ms-1"></i>
                                        </button>
                                        {open && (
                                            <div id="dropContent" className="dropdown-content">
                                                <p className="m-0"
                                                   onClick={() => navigate(HUBEX_APP_ROUTES.RUBY_ON_RAILS)}>Ruby on
                                                    Rails</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Nav.Link className="nav-links-main costNav-main d-flex align-items-center"
                                          onClick={() => scrollIntoViewHandler('costNav-main')}>Request</Nav.Link>
                                <Nav.Link className="nav-links-main projNav-main d-flex align-items-center"
                                          onClick={() => scrollIntoViewHandler('projNav-main')}>Projects</Nav.Link>
                                <Nav.Link className="nav-links-main cntctNav-main d-flex align-items-center"
                                          onClick={() => scrollIntoViewHandler('cntctNav-main')}>Contact</Nav.Link>
                            </Nav>
                            <EButton width="8rem" bgColor="#01B9DA" primary color="white"
                                     className="mx-2 gt-btn fw-bolder"
                                     onClick={() => scrollIntoViewHandler('footerNav')}>Get in Touch</EButton>
                        </Navbar.Collapse>
                    </Container>
                }
            </Navbar>


            {/* Sidebar overlay */}

            <div className="side-overlay p-3 shadow-sm" ref={sideOverlay}>
                <div className="d-flex align-items-center justify-content-between">
                    <a href="#/"><img src={HubexLogo} alt="logo" width="140px"/></a>
                    <button className="so-cncl_btn bg-transparent border-0" onClick={closeSideBar}><i
                        className="fas fa-times fs-5 so-cncl_icon"></i></button>
                </div>
                <div className="p-5 so-main">
                    <div className="so-list d-flex flex-column">
                        <span><Nav.Link onClick={() => scrollIntoViewHandler('homeNav')}
                                        className="side-navLinks">Home</Nav.Link></span>
                        <span><Nav.Link onClick={() => scrollIntoViewHandler('servicesNav-main')}
                                        className="side-navLinks">Services</Nav.Link></span>
                        <span><Nav.Link onClick={() => scrollIntoViewHandler('costNav-main')}
                                        className="side-navLinks">Request </Nav.Link></span>
                    </div>
                    <p className="ms-4 mt-5">It's not just what it looks like and <br/>feel like. Design is how it
                        works.</p>
                    <p className="text-start mt-3 ms-4 fw-bolder text-uppercase">Steve Jobs</p>
                </div>
            </div>
        </div>
    );
}
export default MainNavbar;