import React, {useEffect} from 'react';
import "./Services.css";
import Card from "./Card";
import Steps from "./Steps"
import FooterCard from "./FooterCard";
import WhyChooseUs from "./WhyChooseUs";
import ContactForm from "./ContactForm";
import Testimonial from "../Testimonial";
import MainNavbar from "../../../Includes/MainNavbar";
import Header from "./Header";
import {Helmet} from "react-helmet";


const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    },[])
    return (
        <>
            <Helmet>
                {/* Hotjar Tracking Code for ROR landing page*/}
                <script>
                    {`
                    (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:3290065,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
                </script>
            </Helmet>
            <MainNavbar flag="noNavBar"/>
            <Header/>
            <Card/>
            <Steps/>
            <WhyChooseUs/>
            <Testimonial/>
            <ContactForm/>
            <FooterCard/>
        </>
    );
}


export default Services