import React from "react";
import { Container } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import TestimonailItem from "./TestimonialItem";
import tImage1 from '../../../Assets/Images/raj-me.png'
import tImage2 from '../../../Assets/Images/gas2go.png'
import tImage3 from '../../../Assets/Images/shiroy.png'
import tImage4 from '../../../Assets/Images/buckpalmer.png'
import tImage5 from '../../../Assets/Images/gnrtn.png'
import "./Testimonial.css";

const Testimonial = () => {
    const testimonialsData = [
        { imageSrc: tImage1, tName: "Raj Salhotra", tDesc: "We did not have a functioning website nor did we have a functioning search database. Moreover, we could not make website edits beyond a basic Wix design. Hubextech helped us by designing, coding, and managing all aspects of our website. They first helped us design the website, they then coded it, and finally they ensured we had a functioning search algorithm. Students from across Texas are now using the Momentum Education website. Without Hubextech, we could not have achieved our goals of helping students."},
        { imageSrc: tImage2, tName: "Usman Ahmad", tDesc: "Honestly they were excellent in all areas. Very professionally communicated, delivered, and successfully closed our project together. I'd hire them any chance I get, they helped salvage our product and transform it into a completely built out web platform for our company. Very knowledgeable in backend development with a growing arsenal as Hubextech seems to always be growing. Strong communication and organization skills."},
        { imageSrc: tImage3, tName: "Shiroy Aspandiar", tDesc: "Hubextech team is prompt, responsive, reliable, and most importantly all are exceptional communicators. They routinely asked the right questions in order to ensure that the code to be developed was well thought out, and responsive to our users needs. Quick to think on their feet and to execute solutions to challenges as they arrive, and will give their 100% to each client."},
        { imageSrc: tImage4, tName: "Buck Palmer", tDesc: "Team Hubextech were great to work with for many months, they were very on-time with all the milestones and amazing quality of work. They went above and beyond my expectations for such a long project. Definitely will be working with them again!"},
        { imageSrc: tImage5, tName: "GNRTN", tDesc: "Hubextech have got AMAZING developers, they got all my work done in half the time I thought it would take with no drop in quality. They makes sure you get what you want, and will not stop until their work is perfect. 10/10 would recommend this team to anyone who is looking for a quality and prompt work."},
    ]

        // Slick Carousel setting---start
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            speed: 500,
            slidesToScroll: 1,
            autoplay:true,
            arrows:true,
            autoplaySpeed: 3000,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        infinite: true,
                        dots: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        }

    return (
            <section className="testimonial text-center navlinks-section" id='test-offset'>
                <Container>
                    <div className="heading white-heading">Testimonial</div>
                    <div className="carousel-inner">
                        <Slider {...settings}>
                            {
                                testimonialsData.map((curr, index) => {
                                    return <TestimonailItem title={curr.tName} ProjDesc={curr.tDesc} ProjImage={curr.imageSrc} key={index} />
                                })
                            }
                        </Slider>
                    </div>
                </Container>
            </section>
    );
}

export default Testimonial;