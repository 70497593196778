import React from "react";
import './PorjectItem.css';
const TestimonailItem = (props) => {
    return (
        <>
            <div className="carousel-item active">
                <div className="testimonial_slide d-flex flex-column justify-content-center align-items-center">
                    <img
                        src={props.ProjImage}
                        className="img-circle img-responsive mb-4"
                        alt="img"
                    />
                    <h4 className="mb-5">
                        <b>
                            {props.title}
                        </b>
                    </h4>
                    <div>
                        <p className="testimonial_alt mb-5">
                            {props.ProjDesc}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TestimonailItem;