import React, { useEffect, useState } from 'react';
import ContactUs from '../HomePage/SubSections/ContactUs';
import Footer from '../Includes/Footer';
import StatCounter from '../HomePage/SubSections/StatCounter';
import HeroSection from './SubSections/HeroSection';
import Services from './SubSections/Services';
import Cost from './SubSections/CostSection/Cost';
import Testimonial from './SubSections/Testimonial';
import Projects from './SubSections/Projects';
//Preloader
import Preloader from '../UI/Preloader';



const HomePage = () => {
        const [loading, setLoading] = useState(true)
        useEffect(() => {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 3000)
        }, [])
    return (
        <div>
            {loading ? <Preloader/>:
            <>
            <HeroSection />
            <Services />
            <Cost/>
            <Projects/>
            <Testimonial />
            <StatCounter />
            <ContactUs />
            <Footer />
            </>
            }
        </div>
    );
}
export default HomePage;