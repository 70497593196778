import React, { useState, useEffect } from 'react';
import HeroSectionResume from './Subsections/HeroSectionResume';
import { Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router';
import SideMenu from './Includes/SideMenu';
import HUBEX_APP_API from '../Services/API'

const ResumesHomePage = () => {

    let { slug } = useParams();

    //set the data in constant for use the data from parent to child
    const [resumeData, setResumeData] = useState('');

    useEffect(() => {
        const fecthingData = () => {
            const baseURL = `${process.env.REACT_APP_BASE_URL}${HUBEX_APP_API.EMPLOYEES_API}${slug}${HUBEX_APP_API.POPULATE_ALL}`;
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + process.env.REACT_APP_AUTH_KEY;
            axios.get(baseURL)
                .then(resp => {
                    setResumeData(resp.data.data[0])
                })
                .catch(err => {
                    console.log(err)
                })
        }
        fecthingData();
    }, [slug]);
    //Handling the side bar open on click event
    const [sbOpen, setSbOpen] = useState(false);
    const SideBarHandler = () => {
        setSbOpen(true);
    };
    const closeSidebarHandler = () => {
        setSbOpen(false);
    };

    if (!resumeData) return null;

    return (
        <section className='bg-main-resume'>
            <Row className='m-0'>
                <Col xl={3} className='p-0'>
                    <Container className='p-0 d-flex justify-content-end'>
                        <SideMenu
                            SideMenuRespData={resumeData}
                            OpenSideBarHandler={sbOpen}
                            CloseSidebarHandler={closeSidebarHandler}
                        />
                    </Container>
                </Col>
                <Col xl={9} className='p-0'>
                    <Container>
                        <HeroSectionResume
                            ResumeRespData={resumeData}
                            SidebarOpen={SideBarHandler}
                        />
                    </Container>
                </Col>
            </Row>
        </section>
    )
}

export default ResumesHomePage;
