import React from 'react'
import TypeWriter from '../../UI/TypeWriter';
import SkillsResume from './SkillsResume';
import PersonalResume from './PersonalResume';
import ContactResume from './ContactResume';
import AboutResume from './AboutResume';
import './HeroSectionResume.css';
import logo from "../../../Assets/Images/logo.png";
import logo_mobile from "../../../Assets/Images/logo-mobile.png";


const HeroSectionResume = (props) => {

    const responseData = props.ResumeRespData;

    const openSideBarHandler = () => {
        props.SidebarOpen()
    }
    return (
        <>
            <section id='resume-home' className='resume-layout sideMenu-links-section'>
                <div className='card-Hero card-main'>
                    <div className='intro-resume-main px-4 d-flex flex-column align-items-start justify-content-center'>
                        <div className="intro-main-style footer-logo top-0 pt-3 position-absolute d-none d-md-block">
                            <a href="https://www.hubextech.com/">
                                <img src={logo} className="img-fluid" width='70%' alt="logo" />
                            </a>
                        </div>
                        <div className="footer-logo top-0 pt-3 position-absolute d-md-none">
                            <a href="https://www.hubextech.com/">
                                <img src={logo_mobile} className="img-fluid" width='90%' alt="logo" />
                            </a>
                        </div>
                        <header className='header-btn position-fixed'>
                            <button onClick={openSideBarHandler} className='bg-transparent d-xl-none px-2'>
                                <i className='fas fa-bars'></i>
                            </button>
                        </header>
                        <div className='intro-main-style pt-5 text-start'>
                            <div className='resume-hero-main display-4 fw-bold pb-3'>
                                {responseData.name}
                            </div>
                            <div className='fs-2 px-1 fw-bold'>
                                <span>
                                    I'm
                                    <span className='px-2 typewritter-effect'>
                                        <TypeWriter />
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <AboutResume AboutRespData={responseData} />
                <SkillsResume SkillRespData={responseData} />
                <PersonalResume PrRespData={responseData} />
                <ContactResume />
            </section>
        </>
    );
}
export default HeroSectionResume;