import React, { useEffect } from "react";
import "./TermsAndConditions.css";
import HubexLogo from '../../Assets/Images/logo.png'

function TermsAndConditions() {

    useEffect(() => {
        window.scrollTo(0, 0)
    })


    return (
        <section>
            <header>
                <nav className="est_navbar navbar navbar-expand-lg navbar-light bg-white shadow-sm-main">
                    <div className="container">
                        <div className="d-flex justify-content-between w-100">
                            <a className="navbar-brand" href="https://hubextech.com"><img src={HubexLogo} alt="hubextech" width="140px" /></a>
                        </div>
                    </div>
                </nav>
            </header>
            <div className="container">
                <h2 className="text-center fw-bold my-5"> Terms and conditions </h2>
                <div className="termsandconditions_section">
                    <div className="my-4">
                        <h4>
                            <b>Rights</b>
                        </h4>
                        <p className="my-4">
                            By following these Terms, you comprehend and concur that Hubextech
                            saves all rights to quickly end/eliminate your admittance to this site
                            without earlier notification and without showing any explanation in
                            the event that it associates breaks or infringement with these Terms
                            of Use or other fused arrangements/rules or demands by law
                            implementation or other government offices or whatever other
                            explanation that Hubextech sensibly accepts useful for such
                            end/evacuation. You further comprehend and concur that all
                            terminations will be made at Hubextech's sole carefulness and that
                            Hubextech will not be responsible to you nor any outsider for any end
                            of your record or admittance to this site.
                        </p>
                    </div>
                    <div className="my-4">
                        <h4>
                            <b>Reservation of Rights</b>
                        </h4>
                        <p className="my-4">
                            Any utilization of this current site's substance including text,
                            pictures, designs, recordings, logos, and symbols, is the dedicated
                            property of Hubextech and is ensured by the U.S. and international
                            copyright laws - utilization of which for business objects is totally
                            restricted. By no means may any of the substance on this site be
                            duplicated, imitated, adjusted, changed (counting altering any of the
                            innovative shields), distributed, or appropriated in any structure
                            without acquiring earlier authorization. Unapproved utilization of any
                            data from this site might abuse copyright, trademark, and other laws.
                            To demand consent to utilize any of the substance on this site for
                            business purposes, kindly reach us at
                            <a
                                href="mail:sales@hubextech.com"
                                className="termsandconditions_link"
                            >
                                &nbsp;sales@hubextech.com.
                            </a>
                        </p>
                    </div>
                    <div className="my-4">
                        <h4>
                            <b>Utilization of Website's Content</b>
                        </h4>
                        <p className="my-4">
                            Hubextech, as per these Terms, awards you a restricted, non-selective,
                            and revocable permit to access, peruse and download the given
                            promoting content for inside and business use, furnished that you
                            follow these Terms and don't adjust the substance in any capacity,
                            including holding all copyright and exclusive notification showed on
                            the site. Some other utilization of this current site's substance
                            without Hubextech's earlier assent is completely denied.
                        </p>
                    </div>
                    <div className="my-4">
                        <h4>
                            <b>Electronic interchanges</b>
                        </h4>
                        <p className="my-4">
                            By utilizing our site, you agree to get electronic interchanges from
                            us, for example, sees and other data concerning the site. This
                            correspondence is essential for your relationship with us.
                        </p>
                    </div>
                    <div className="my-4">
                        <h4>
                            <b>Link to Third-Party Sites</b>
                        </h4>
                        <p className="my-4">
                            We might give and in some cases utilize content upheld or given by
                            outsider sites. Our arrangement of a connection to some other site or
                            area is for your benefit just and doesn't mean our underwriting of any
                            such other site or area, or its substance. Hubextech doesn't
                            acknowledge risk for any such data found whatsoever other site or web
                            area and bears no obligation regarding such outsider sites that are
                            administered by the terms of utilization and protection approaches of
                            the particular outsider substance suppliers.
                        </p>
                    </div>
                    <div className="my-4">
                        <h4>
                            <b>Disclaimer</b>
                        </h4>
                        <p className="my-4">
                            To the most extreme degree allowed by material law, we bar all
                            portrayals, guarantees, and conditions identifying with our site and
                            the utilization of this site. Nothing in this disclaimer will:
                        </p>
                        <ol>

                            <li>
                                <p>
                                    Restrict or prohibit our or your responsibility for death or
                                    individual injury.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Restrict or prohibit our or your responsibility for extortion or
                                    fake deception.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Limit any of our or your liabilities in any capacity that isn't
                                    allowed under appropriate law.
                                </p>
                            </li>
                            <li>
                                <p className="my-4">
                                    Prohibit any of our or your liabilities that may not be avoided
                                    under relevant law.
                                </p>
                            </li>
                            <li>
                                <p className="my-4">
                                    The impediments and denials of obligation set in this Section and
                                    somewhere else in this disclaimer:
                                </p>
                            </li>
                            <ol>
                                <li>
                                    <p className="my-4">are dependent upon the first passag</p>
                                </li>
                                <li>
                                    <p className="my-4">
                                        administer all liabilities emerging under the disclaimer,
                                        incorporating liabilities emerging in agreement, in misdeed, and for
                                        break of legal obligation.
                                    </p>
                                </li>
                            </ol>

                        </ol>
                        <p className="my-4">
                            However long the site and the data and administrations on the site are
                            given for nothing, we won't be responsible for any misfortune or harm
                            of any nature.
                        </p>
                    </div>
                </div>
            </div>
            <header>
                <nav className="est_navbar navbar navbar-expand-lg navbar-light bg-white shadow-sm-main mt-5">
                    <div className="container d-flex align-items-center justify-content-center w-100">
                        <h6 className="my-3 fw-bold">
                            2022 &copy;
                            <a href="https://www.hubextech.com/" className="mx-2 copyright-main">
                                HubexTech
                            </a>
                            - All Rights Reserved.
                        </h6>
                    </div>
                </nav>
            </header>
        </section>

    );
}

export default TermsAndConditions;
