import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../Assets/Images/hubex2-white.png";
import "./Footer.css";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import HUBEX_APP_API from "../Services/API";

const Footer = () => {

    //Getting the value from get in touch input
    const [gitValue, setGitValue] = useState({
        gitEmail: ''
    })

    const getInTouchHandler = (e) => {
        const keyName = e.target.name;
        const keyValue = e.target.value;
        setGitValue((prev) => {
            return {
                ...prev,
                [keyName]: keyValue
            }
        })
    }

    const apiEmailPostingHandler = async () => {
        const baseURL = `${process.env.REACT_APP_BASE_URL}${HUBEX_APP_API.LEADS}`;
        const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        const result = regex.test(gitValue.gitEmail);
        if (result) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.REACT_APP_AUTH_KEY;
            await axios.post(baseURL, {
                data: {
                    email: gitValue.gitEmail,
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        swal("Thank you!", "Email has been submitted successfuly.", "success");

                    }

                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        swal("Oops!", "There is something wrong with server!!", "error")
                    }
                })


        } else {
            swal("Wrong Input", "Please enter a valid email!", "error")

        }
    }

    const getInTouchFormHandler = (e) => {
        e.preventDefault();
        apiEmailPostingHandler();
    }


    return (
        <>
            <footer className="footer-section navlinks-section" id='footerNav'>
                <Container>
                    <div className="footer-content pt-5 pb-5">
                        <Row>
                            <div className="footer-logo">
                                <a href="https://www.hubextech.com/">
                                    <img src={logo} className="img-fluid" alt="logo" />
                                </a>
                            </div>
                            <Col className="col-12 col-xl-5 col-lg-5 mb-30">
                                <div className="footer-widget footer-margin">
                                    <div className="footer-widget-heading">
                                        <h3>HubexTech</h3>
                                    </div>
                                    <small className="pe-5">
                                        We develop innovative and creative products and services that provide total communication and information solutions. Among a plethora of services, web design and development, tailor-made applications, ERPs, CRMs, e-commerce solutions, business-to-business applications, business-to-client applications, managed hosting and internet portal management are few that we offer.
                                    </small>
                                </div>
                            </Col>
                            <Col className="col-12 col-xl-4 col-lg-4 mb-30">
                                <div className="footer-widget footer-margin">
                                    <div className="footer-widget-heading">
                                        <h3>Get In Touch</h3>
                                    </div>
                                    <div className="footer-text mb-25">
                                        <p className="footer-nav">
                                            Interested in any of our SERVICES? Talk to our experts.
                                        </p>
                                    </div>
                                    <div className="git-form">
                                        <form onSubmit={getInTouchFormHandler}>
                                            <div className="form-group res-input">
                                                <input
                                                    type="email"
                                                    placeholder="Enter Email"
                                                    className="form-control"
                                                    name="gitEmail"
                                                    required
                                                    onChange={getInTouchHandler}
                                                />
                                            </div>
                                            <button type="submit">
                                                <i className="fab fa-telegram-plane"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12 col-xl-3 col-lg-3 mb-30">
                                <div className="footer-widget footer-margin">
                                    <div className="footer-widget-heading">
                                        <h3>Connect With Us</h3>
                                    </div>
                                    <div className="media-icons">
                                        <a href="https://twitter.com/HubexT" target="_blank" rel="noreferrer">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                        <a href="https://www.linkedin.com/company/hubex-tech/" target="_blank" rel="noreferrer">
                                            <i className="fab fa-linkedin-in" ></i>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="copyright-area">
                    <Container>
                        <Row>
                            <Col className="col-xl-12 col-lg-12 d-flex justify-content-md-between justify-content-center flex-wrap ">
                                <div className="copyright-text my-2">
                                    <p>
                                        {new Date().getFullYear()} &copy;
                                        <a href="https://www.hubextech.com/" className="ms-2">
                                            HubexTech&nbsp;
                                        </a>
                                        - All Rights Reserved.
                                    </p>
                                </div>
                                <div className="copyright-text d-flex flex-wrap my-2">
                                    <Link to="/privacypolicy " target="_blank" rel="noreferrer" className="text-decoration-none me-3">
                                        <p>Privacy Policy</p> </Link>
                                    <Link to="/termsandconditions" target="_blank" rel="noreferrer" className="text-decoration-none">
                                        <p>Terms and Conditions</p> </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </>
    );
};
export default Footer;
