import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import './ContactResume.css';
import CalendlyIcon from "../../../Assets/Images/Calendly.svg";
import LinkedInIcon from "../../../Assets/Images/Linked-in.svg";
import TwitterIcon from "../../../Assets/Images/Twitter.svg";

function ContactResume() {
    return (
        <section id='resume-contact'
                 className='resume-main card-Hero card-main resume-section-bg sideMenu-links-section d-flex justify-content-center'>
            <Container>
                <div className='section-title'>
                    <h2>Contact</h2>
                </div>
                <Row className='text-start'>
                    <div className='d-flex justify-content-start'>
                        <p className='fs-5 py-2'>Don't hesitate to contact us.</p>
                    </div>
                    <Col md={6}>
                        <div className='py-3 py-lg-5'>
                            <div className='contact-title'>
                                <h3>Find Us</h3>
                            </div>
                            <ul className='contact-list list-unstyled'>
                                <li>
                                    <a href='https://www.google.com/maps/place/4+Riverina+Blvd,+Brookfield+VIC+3338,+Australia/@-37.708397,144.545497,16z/data=!4m5!3m4!1s0x6ad693151396b1df:0xfaf6c1c475e03ae2!8m2!3d-37.7083967!4d144.545497?hl=en'
                                       target='_blank' rel='noreferrer'>
                                        <i className='fas fa-map-marker-alt me-2'></i>
                                        4 Riverina boulevard, Brookfield 3338, Melbourne, Australia.
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.google.com/maps/place/Southville+Solutions/@31.4579162,74.2738551,17z/data=!3m1!4b1!4m5!3m4!1s0x391901ed6dd6c28f:0x431c3944dd59341c!8m2!3d31.4625077!4d74.2765154'
                                       target='_blank' rel='noreferrer'>
                                        <i className='fas fa-map-marker-alt me-2'></i>
                                        85 Service Rd, Block R1, Phase 2, Johar Town, Lahore, Pakistan.
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='py-3 py-lg-5'>
                            <div className='contact-title'>
                                <h3>Call Us</h3>
                            </div>
                            <ul className='contact-list list-unstyled'>
                                <li>
                                    <a href='tel:+61426508849' target='_blank' rel='noreferrer'>
                                        <i className='fas fa-mobile-alt me-2'></i>
                                        + 61 426 508 849
                                    </a>
                                </li>
                                <li>
                                    <a href='tel:+923204027604' target='_blank' rel='noreferrer'>
                                        <i className='fas fa-mobile-alt me-2'></i>
                                        +92 320 402 7604
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='py-3 py-lg-5'>
                            <div className='contact-title'>
                                <h3>Mail Us</h3>
                            </div>
                            <ul className='contact-list list-unstyled'>
                                <li>
                                    <a href='mailto:sales@hubextech.com' target='_blank' rel='noreferrer'>
                                        <i className='fas fa-envelope me-2'></i>
                                        sales@hubextech.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='py-3 py-lg-5'>
                            <div className='contact-title'>
                                <h3>Connect with Us</h3>
                            </div>
                            <div className='d-flex contact-social-icons'>
                                <a href='https://calendly.com/moeez-haider-hubextech/' target='_blank'
                                   rel='noreferrer'>
                                    <img src={CalendlyIcon} alt="CalendlyIcon"/>
                                </a>
                                <a href='https://www.linkedin.com/company/hubex-tech/' target='_blank' rel='noreferrer'>
                                    <img src={LinkedInIcon} alt="LinkedInIcon"/>
                                </a>
                                <a href='https://twitter.com/HubexT' target='_blank' rel='noreferrer'>
                                    <img src={TwitterIcon} alt="TwitterIcon"/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ContactResume
