import React from "react";
import './ThankYouPage.css';
import HubexTechLogo from "../../Assets/Images/logo.png";

const ThankYouPage = () => {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center box_description_main">
                <div className="text-center text-light box_description py-5">
                    <div className='d-flex align-items-center pb-5'>
                        <a className="navbar-brand" href="https://hubextech.com">
                            <img src={HubexTechLogo} alt="hubextech" width="140px"/>
                        </a>
                    </div>
                    <h1 className="display-2 fw-bold">Thank You!</h1>
                    <p className="my-4 fs-5 text-start">
                        Thank you for filling out the form | Please check your email inbox & promotion tab | Where you
                        can get access to the cost estimation calculator and guide for your project roadmap.
                    </p>
                    <div className="d-flex justify-content-center mt-5 mb-3">
                        <a className="text-decoration-none text-light button-75"
                           href="https://calendly.com/moeez-haider-hubextech/hubextech">
                            Book Meeting Slot
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ThankYouPage