import React, { useEffect } from 'react'
import MainNavbar from '../../Includes/MainNavbar';
import './HeroSection.css'
import HsVector from '../../../Assets/Images/background-hsVector.png'
import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css'

const HeroSection = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])
    const scrollIntoViewHandler = (id) => {
        //This closeSideBar function is just for mobile devices
        //It will help us when a user is on mobile device and open the sidebar 
        //and then click on clicks then the navbar will be close and scroll to the desired section. 
        const anchor = document.getElementById(id);
        const y = anchor.getBoundingClientRect().top
        anchor.scrollIntoView({ behavior: "smooth", top: y });
    }
    return (
        <>
            <section className="intro-main navlinks-section" id="homeNav">
                <MainNavbar />
                <Container>
                    <Row className="hero-text">
                        <Col xs={12} md={12} lg={6} className="px-4 mt-5">
                            <div className="mt-5" data-aos={"fade-down"} data-aos-duration={1000} data-aos-delay={100}>
                                <span className="mb-5 display-4 heading-bold"><span className="intro-desc-text fw-bolder text-white d-block heading-bold">Get your apps</span> Audited Today</span>
                                <p className='mt-4'>
                                    We transform dreams into reality, research into insight, & coffee into<br /> unforgettable experiences on the phone & Web
                                </p>
                            </div>
                            <div className="mt-4 hero-section-btns">
                                <button className="text-uppercase me-2 my-2 btn-mau" onClick={() => scrollIntoViewHandler('costNav-main')} data-aos={"fade-up"} data-aos-duration={500} data-aos-delay={400}>Request a demo</button>
                                <button className="text-uppercase me-2 my-2 btn-sow" onClick={() => scrollIntoViewHandler('projNav-main')} data-aos={"fade-up"} data-aos-duration={500} data-aos-delay={800}>See our Work</button>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={6} className="hs-vector_col" data-aos={"fade-up"} data-aos-duration={1000} data-aos-delay={200}>
                            <img src={HsVector} alt="hs-vector" width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default HeroSection;