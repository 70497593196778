import React from 'react';
import { Col } from 'react-bootstrap';
import './PersonalResume.css';
import DOMPurify from 'dompurify'
import { convertTimezoneToFullYear } from '../../Utilities/Utilities';
import ReactMarkdown from 'react-markdown';


//Experience Container Component
const ExperienceContainer = (props) => {

    const rawHTML = DOMPurify.sanitize(props.descHTML);
    const startTimezone = props.start;
    const endTimezone = props.end;
    const startYear = convertTimezoneToFullYear(startTimezone, 'start')
    const endYear = convertTimezoneToFullYear(endTimezone, 'end')


    return (
        <Col md={6}>
            <div className='resume-border'>
                <div className='fs-4 fw-bolder mb-2'>{props.title}</div>
                <div className='resume-session fs-6 fw-bolder mb-2'>{startYear} - {endYear}</div>
                <p className='fs-5 fw-bold'>{props.company}</p>
                <div className='pl-5'>
                    <ReactMarkdown>
                        {rawHTML}
                    </ReactMarkdown>
                </div>
            </div>
        </Col>
    );
}


export default ExperienceContainer;
