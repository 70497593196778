import React from 'react';
import { convertTimezoneToFullYear } from '../../Utilities/Utilities';


//Education Container Component
const EducationContainer = (props) => {

    const startTimezone = props.start;
    const endTimezone = props.end;
    const startYear = convertTimezoneToFullYear(startTimezone, 'start')
    const endYear = convertTimezoneToFullYear(endTimezone, 'end')


    return (
        <div className='resume-border'>
            <div className='fs-4 fw-bolder mb-2'>{props.title}</div>

            <div className='resume-session fs-6 fw-bolder mb-2'>{startYear} - {endYear}</div>
            <p className='fs-5 fw-bold'>{props.institute}</p>

            <p>{props.desc}</p>
        </div>
    );
}
export default EducationContainer;
