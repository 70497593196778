import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import EButton from "../../../UI/Button";
import './Cost.css'
import CostCalculations from "./CostCalculations";
import ContactFrom from './ContactForm'
import ContactForm from "./ContactForm";
import axios from "axios";
import swal from 'sweetalert';
import HUBEX_APP_API from "../../../Services/API";


const Cost = () => {
    //Cost Prices
    const costPrices = {
        frontend: 5,
        backend: 8,
        fullstack: 10,
        uiux: 7,
        Beginner: 7,
        Intermediate: 10,
        Expert: 12
    }


    //Animation Operation of Cost Section
    const dp = useRef(null);
    const cp = useRef(null);
    const crMain = useRef(null);
    const cntctMain = useRef(null)
    const cDetails = useRef(null);
    const overlay = () => {
        dp.current.classList.add('right-overlay_anim',)
        cp.current.classList.add('left-overlay_anim')
        cntctMain.current.classList.add('cntct-form_anim')
        crMain.current.classList.add('cr-main_anim')
        cDetails.current.classList.add('choiceDetails_anim')

    }
    const offOverlay = () => {
        dp.current.classList.remove('right-overlay_anim')
        cp.current.classList.remove('left-overlay_anim')
        cntctMain.current.classList.remove('cntct-form_anim')
        crMain.current.classList.remove('cr-main_anim')
        cDetails.current.classList.remove('choiceDetails_anim')
    }




    const [clickUpPrices, setClickUpPrices] = useState({
        first: costPrices.frontend,
        second: costPrices.Intermediate,
        third: 2
    })

    const [costData, setCostData] = useState({
        spType: 'frontend',
        clType: 'Intermediate',
        ePeriod: '6-12 months',
    })
    //Setting the State for Total Price
    const totalCalPrice = (clickUpPrices.first * clickUpPrices.second) - clickUpPrices.third;
    const [initialCostPrice, setInitialCostPrice] = useState(48)

    let contactFromData = {};

    const spActive = useRef(null)
    const clActive = useRef(null)
    const epActive = useRef(null)
    const costChangeHandler = (e) => {
        if (e.target.classList.contains('sp-type_btn')) {
            //First we have to uncheck the existing checked button
            spActive.current.classList.remove('checked')
            spActive.current = e.target;
            e.target.classList.add('checked');
            const spValue = e.target.textContent;
            const name = e.target.name;
            // We need to store the selected value
            changeData('spType', spValue)
            // We need to change the rate as per selection of user
            setClickUpPrices(prev => {
                return {
                    ...prev,
                    first: costPrices[name]
                }
            })

        }
        else if (e.target.classList.contains('cl_btn')) {
            //First we need to unfocus the already selected button
            clActive.current.classList.remove('checked')
            clActive.current = e.target;
            e.target.classList.add('checked');
            const clValue = e.target.textContent;
            const name = e.target.name;
            // We need to store the selected value
            changeData('clType', clValue)
            // We need to change the rate as per selection of user
            setClickUpPrices(prev => {
                return {
                    ...prev,
                    second: costPrices[name]
                }
            })

        }
        else if (e.target.classList.contains('ep_btn')) {
            //First we need to unfocus the already selected button
            epActive.current.classList.remove('checked')
            epActive.current = e.target;
            e.target.classList.add('checked');
            const epValue = e.target.textContent;
            const value = e.target.value;
            // We need to store the selected value
            changeData('ePeriod', epValue)
            // We need to change the rate as per selection of user
            setClickUpPrices(prev => {
                return {
                    ...prev,
                    third: value
                }
            })
        }

    }
    const changeData = (key, value) => {
        setCostData((prevData) => {
            return {
                ...prevData,
                [key]: value
            }
        })
    }



    const apiCostDataPostingHandler = async () => {

        const baseURL = `${process.env.REACT_APP_BASE_URL}${HUBEX_APP_API.LEADS}`;
        const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        const result = regex.test(contactFromData.contactEmail);
        if (result) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.REACT_APP_AUTH_KEY;
            await axios.post(baseURL, {
                data: {
                    full_name: contactFromData.contactName,
                    email: contactFromData.contactEmail,
                    message: contactFromData.contactMessage,
                    developer_type: costData.spType,
                    experiance_level: costData.clType,
                    employee_duration: costData.ePeriod,
                    total_cost: String(totalCalPrice)
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        swal("Thank you!", "Request has been submitted successfuly", "success");

                    }

                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        swal("Oops!", "There is something wrong with server!!", "error")
                    }
                })


        } else {
            swal("Wrong Input", "Please enter a valid email!", "error")

        }
    }
    const onGetContactFormDataHandler = (data) => {
        contactFromData = data;
    }
    const animateNumber = (start, end, obj) => {
        if (start === end) {
            return;
        }
        else {

            if (start > end) {
                setInitialCostPrice(end)
                let myInterval = setInterval(() => {

                    start = start - 1;
                    obj.current.innerHTML = `$${start}`;
                    if (start === end) {
                        clearInterval(myInterval);
                    }
                }, 40)

            } else {
                setInitialCostPrice(end)
                let myInterval = setInterval(() => {
                    start = start + 1;
                    obj.current.innerHTML = `$${start}`;
                    if (start === end) {
                        clearInterval(myInterval);
                    }
                }, 40)
            }
        }
    }
    const tpTag = useRef(null);
    function animateValue(obj, start, end) {
        animateNumber(start, end, obj)
    }
    animateValue(tpTag, initialCostPrice, totalCalPrice)


    return (
        <div className="cost-main-bg">
            <div className="cost-main_cont container-xxl p-0 navlinks-section" id="costNav-main">
                <div className="">
                    <Row className="mx-0">
                        <Col md={6} className="cst p-0 position-relative">
                            <div className="cost-left py-3 p-md-5 container d-flex justify-content-center  h-100" ref={cp} >
                                <div className="cl-main" >
                                    <div className="mt-4 mb-5 res-cost-heading">
                                        <span className="display-6 heading-bold">Cost Per Dev</span>
                                        <p className="mt-2">Specify your requirements to a potential candidate to see the average rate</p>
                                    </div>
                                    <div className="cost-btns-pills" onClick={costChangeHandler}>
                                        <div>
                                            <span className="my-3 fw-bolder d-block">Specialist type</span>
                                            <button className="sp-type_btn checked text-uppercase" name="frontend" ref={spActive}>frontend</button>
                                            <button className="sp-type_btn text-uppercase" name="backend">backend</button>
                                            <button className="sp-type_btn text-uppercase" name="fullstack">fullstack</button>
                                            <button className="sp-type_btn text-uppercase" name="uiux">uiux</button>
                                        </div>
                                        <div>
                                            <span className="my-3 fw-bolder d-block">Cognition level</span>
                                            <div className="cl-main">
                                                <button className="cl_btn text-uppercase" name="Beginner">Beginner</button>
                                                <button className="cl_btn text-uppercase checked" name="Intermediate" ref={clActive}>Intermediate</button>
                                                <button className="cl_btn text-uppercase" name="Expert">Expert</button>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="my-3 fw-bolder d-block">Employment term</span>
                                            <div className="ep-main">
                                                <button className="ep_btn ep_btn-active text-uppercase" value="0">1-3 months</button>
                                                <button className="ep_btn text-uppercase" value="1">3-6 months</button>
                                                <button className="ep_btn text-uppercase checked" value="2" ref={epActive}>6-12 months</button>
                                                <button className="ep_btn text-uppercase" value="3">1 year +</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="choiceDetails position-absolute w-100 h-100 top-0 px-5 py-3 d-flex flex-column justify-content-center align-items-center" ref={cDetails}>
                                <CostCalculations Data={costData} totalPrice={totalCalPrice} AnimFunction={offOverlay} />
                            </div>
                            <div className="cntct-form position-absolute w-100 h-100 top-0 p-5 d-flex justify-content-center align-items-center" ref={cntctMain}>
                                <div className="position-relative">
                                    <ContactFrom cntctMain={cntctMain} ContactFormData={onGetContactFormDataHandler} ContactFormSubmitCallHandler={apiCostDataPostingHandler} />
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className="p-0 position-relative d-flex justify-content-end align-items-center cost-right_costs d-none d-sm-block " ref={dp}>
                            <div className="p-5 text-center w-100 h-100 d-flex justify-content-center">
                                <div className="cr-main d-flex flex-column justify-content-center px-5 rounded" ref={crMain}>
                                    <p className="fs-4 fw-lighter">Average rate per employee</p>
                                    <span className="display-1 fw-bold my-5 me-3" ref={tpTag}>$48</span>
                                    <div className="text-center d-flex flex-column">
                                        <EButton bgColor="none" primary onClick={overlay} className="dp-btn mx-2" > <span>Discuss Partnership</span></EButton>
                                        <div className="mt-2 frwd_Anim_icon"><i className="fas fa-long-arrow-alt-right fs-3"></i></div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        {/* Mobile Responsiveness */}
                        <Col className="d-sm-none d-block p-0">
                            <div className="choiceD_resp p-3 d-flex justify-content-around align-items-center">
                                <CostCalculations Data={costData} totalPrice={totalCalPrice} AnimFunction={offOverlay} />
                            </div>
                            <div className="mx-2 py-5 px-3">
                                <ContactForm cntctMain={cntctMain} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Cost;