import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import footerLogo from "../../Assets/Images/hubex2-white.png"
import { Link } from "react-router-dom";
import "./CaseStudyFooter.css";

function CaseStudyFooter() {


    return (
        <>
            <footer className="justify-content-center footer-pdet-main">
                <Container>
                    <div className="justify-content-center py-5">
                        <div>
                            <div className="title-footer">
                                <div className="footer-pdet-logo">
                                    <a href="https://www.hubextech.com/">
                                        <img src={footerLogo} className="img-fluid" alt="logo" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <Row className="pdet-footer-p footer-pdet-links">
                            <Col lg={4} md={6} className="order-1 pt-5">
                                <div className="footer-pdet-location">
                                    <div className="footer-contact-us-heading">
                                        <h3>
                                            <i className="fas fa-map-marker-alt me-2"></i>
                                            Find Us
                                        </h3>
                                    </div>
                                    <p className="mb-1">
                                        <a href="https://www.google.com/maps/place/4+Riverina+Blvd,+Brookfield+VIC+3338,+Australia/@-37.708397,144.545497,16z/data=!4m5!3m4!1s0x6ad693151396b1df:0xfaf6c1c475e03ae2!8m2!3d-37.7083967!4d144.545497?hl=en">
                                            4 Riverina boulevard, Brookfield 3338, Melbourne, Australia.
                                        </a>
                                    </p>
                                    <p className="mb-1">
                                        <a href="https://www.google.com/maps/place/Southville+Solutions/@31.4579162,74.2738551,17z/data=!3m1!4b1!4m5!3m4!1s0x391901ed6dd6c28f:0x431c3944dd59341c!8m2!3d31.4625077!4d74.2765154">
                                            85 Service Rd, Block R1, Phase 2, Johar Town, Lahore, Pakistan.
                                        </a>
                                    </p>
                                </div>
                            </Col>
                            <Col lg={3} md={6} className="order-2 pt-5">
                                <div className="footer-contact-us-heading">
                                    <h3>
                                        <i className="fas fa-mobile-alt me-2"></i>
                                        Call Us
                                    </h3>
                                </div>
                                <p className="mb-1">
                                    <a href="tel:+61426508849">
                                        + 61 426 508 849
                                    </a>
                                </p>
                                <p className="mb-1">
                                    <a href="tel:+923204027604">
                                        +92 320 402 7604
                                    </a>
                                </p>
                            </Col>
                            <Col lg={3} md={6} className="order-3 pt-5">
                                <div className="footer-contact-us-heading">
                                    <h3>
                                        <i className="fas fa-envelope me-2"></i>
                                        Mail Us
                                    </h3>
                                </div>
                                <a href="mailto:sales@hubextech.com">
                                    sales@hubextech.com
                                </a>
                            </Col>
                            <Col lg={2} md={6} className="order-4 pt-5">
                                <div className="footer-contact-us-heading">
                                    <h3>
                                        <i className="fas fa-link me-2"></i>
                                        Connect Us
                                    </h3>
                                </div>
                                <div className="pdet-media-icons media-icons">
                                    <a href="https://twitter.com/HubexT">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/company/hubex-tech/">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="copyright-area">
                    <Container>
                        <div className="d-flex justify-content-md-between justify-content-center flex-wrap ">
                            <div className="copyright-text my-2">
                                <p>
                                    2022 &copy;
                                    <a href="https://www.hubextech.com/" className="ms-2">
                                        HubexTech&nbsp;
                                    </a>
                                    - All Rights Reserved.
                                </p>
                            </div>
                            <div className="copyright-text d-flex flex-wrap my-2">
                                <Link to="/privacypolicy " target="_blank" rel="noreferrer" className="text-decoration-none me-3">
                                    <p>Privacy Policy</p> </Link>
                                <Link to="/termsandconditions" target="_blank" rel="noreferrer" className="text-decoration-none">
                                    <p>Terms and Conditions</p> </Link>
                            </div>
                        </div>
                    </Container>
                </div>
            </footer>
        </>
    );
}

export default CaseStudyFooter;