// Hubex app route as constant
const HUBEX_APP_ROUTES = {
    // Error Page route as constant
    Error_PAGE: '/*',
    // Home page route as constant
    HOME_PAGE: '/',
    //Service Page Route as constant
    RUBY_ON_RAILS : "/RubyOnRails",
    // Privacy Policy page route as constant
    PRIVACY_POLICY_PAGE: '/privacypolicy',
    // Terms and Conditions page route as constant
    TERMS_AND_CONDITIONS_PAGE: '/termsandconditions',
    // CaseStudy page route as constant
    CASE_STUDY_PAGE: '/casestudy/:name/:projectId',
    // Resumes page route as constant
    RESUMES_PAGE: '/resumes/:slug',
    // Resumes page route as constant
    ESTIMATION_MODULE: '/estimation',
    // ThankYou page route as constant
    THANKYOU_PAGE: '/ThankYou'
}
export default HUBEX_APP_ROUTES