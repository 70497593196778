import React, {useEffect, useState} from "react";
import './Estimate.css';
import HubexTechLogo from '../../Assets/Images/logo.png'
import Form from "./Form";
import {Col, Row} from "react-bootstrap";
import {EstimationData} from "../constants/entimateContent";
import axios from "axios";
import swal from "sweetalert";
import queryString from 'query-string'
import {useLocation} from "react-router-dom";
import {regex} from "../constants/validations";
import html2pdf from 'html2pdf.js'

const Estimate = () => {
    const [webEstimate, setWebEstimate] = useState(0);
    const [iosEstimate, setIosEstimate] = useState(0);
    const [androidEstimate, setAndroidEstimate] = useState(0);
    const [webSelectedData, setWebSelectedData] = useState(EstimationData);
    const [iOSSelectedData, setIOSSelectedData] = useState(EstimationData);
    const [androidSelectedData, setAndroidSelectedData] = useState(EstimationData);
    const [baseColor, setBaseColor] = useState(1);
    const weAppRate = webEstimate * 450;
    const iosAppRate = iosEstimate * 450;
    const androidAppRate = androidEstimate * 450;
    let estimate = weAppRate + iosAppRate + androidAppRate;

    const location = useLocation();
    const value = queryString.parse(location.search);
    const [clientEmail, setClientEmail] = useState('')
    const [isDisabled, setIsDisabled] = useState(true);

    const validateEmail = regex.test(clientEmail);

    const getInTouchHandler = (e) => {
        setClientEmail(e.target.value)
    }
    useEffect(() => {
        setIsDisabled(!validateEmail);
    }, [clientEmail]);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const submitEstimation = () => {
        const baseURL = 'https://yagaeax4swrozjbyc5stpwtl2q0hwrxa.lambda-url.us-west-1.on.aws/';
        axios.post(baseURL, {
            data: {
                send_pdf_email_to: clientEmail,
                query_params: value,
                day_rate: 450,
                total_rate: estimate,
                web: {webSelectedData, weAppRate},
                ios: {iOSSelectedData, iosAppRate},
                android: {androidSelectedData, androidAppRate},
            },
        })
            .then(response => {
                if (response.status === 200) {
                    swal({
                        title: "Thank you!",
                        text: "Estimation has been submitted to your email successfully.",
                        type: "success"
                    }).then(function () {
                            window.location.reload();
                        }
                    );
                }
            })
    }
    const estimationSubmitHandler = (e) => {
        e.preventDefault();
        submitEstimation()
        handlePdfGenerator()

    }
    const websiteForm = () => {
        setBaseColor(1)
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    const iosForm = () => {
        setBaseColor(2)
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    const androidForm = () => {
        setBaseColor(3)
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    // PDF Generator
    let element = document.getElementById('pdfEstimation');
    let opt = {
        margin: 1,
        filename: 'estimation.pdf',
        image: {type: 'png', quality: 1},
        html2canvas: {scale: 2},
        jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
    };
    const handlePdfGenerator = () => {
        html2pdf().from(element).set(opt).save();
    }

    return (
        <section className="est-section">
            <header className=" bg-white shadow-main py-2 w-100">
                <div className="container">
                    <div className='d-flex align-items-center'>
                        <a className="navbar-brand" href="https://hubextech.com">
                            <img src={HubexTechLogo} alt="hubextech" width="140px"/>
                        </a>
                    </div>
                </div>
            </header>
            <div className="container p-0 my-5 shadow-main">
                <div className="p-5 text-dark bg-light text-center">
                    <p className="cost-heading display-5 fw-bold">Estimate My App</p>
                    <p className="sub-heading fs-4">
                        Start the estimation by selecting the options that best match your app and features requirement.
                    </p>
                    <p className="disclaimer fs-5">
                        The provided estimations are approximations but will help you to have a close evaluation of your idea.
                    </p>
                </div>
                <div
                    className={baseColor === 1 ? 'bg-web' : baseColor === 2 ? 'bg-ios' : baseColor === 3 ? 'bg-android' : 'bg-web'}>
                    <Row className="w-100 m-0">
                        <Col md={4} className=" text-center text-white bg-web py-4 cursor"
                             onClick={websiteForm}>
                            <div className="d-flex justify-content-center align-items-center mb-3">
                                <i className="fas fa-desktop me-2 fs-4"></i>
                                <h3 className="fw-bold mb-0">Web</h3>
                            </div>
                            <p className="fs-5">A web app or a <br/>back-end to a mobile app</p>
                        </Col>
                        <Col md={4} className=" text-center text-white bg-ios py-4 cursor"
                             onClick={iosForm}>
                            <div className="d-flex justify-content-center align-items-center mb-3">
                                <i className="fab fa-apple me-2 fs-3"></i>
                                <h3 className="fw-bold mb-0">iOS</h3>
                            </div>
                            <p className="fs-5">An iPhone / iPad app <br/>(Excluding back-end)</p>
                        </Col>
                        <Col md={4} className=" text-center text-white bg-android py-4 cursor"
                             onClick={androidForm}>
                            <div className="d-flex justify-content-center align-items-center mb-3">
                                <i className="fab fa-android me-2 fs-3"></i>
                                <h3 className="fw-bold mb-0">Android</h3>
                            </div>
                            <p className="fs-5">An Android / Tablet app <br/>(Excluding back-end)</p>
                        </Col>
                    </Row>
                    <Form weAppRate={weAppRate} iosAppRate={iosAppRate} androidAppRate={androidAppRate}
                          rootElementId="pdfEstimation" baseColor={baseColor}
                          webEstimate={webEstimate}
                          setWebEstimate={setWebEstimate}
                          iosEstimate={iosEstimate} setIosEstimate={setIosEstimate}
                          androidEstimate={androidEstimate} setAndroidEstimate={setAndroidEstimate}
                          webSelectedData={webSelectedData} setWebSelectedData={setWebSelectedData}
                          iOSSelectedData={iOSSelectedData} setIOSSelectedData={setIOSSelectedData}
                          androidSelectedData={androidSelectedData} setAndroidSelectedData={setAndroidSelectedData}
                    />
                    <Row className="w-100 m-0">
                        <Col md={4}
                             className="text-white bg-web py-5 cursor"
                             onClick={websiteForm}>
                            {webEstimate ?
                                <div
                                    className="d-flex flex-column align-items-start justify-content-center px-3 w-100">
                                    <div className="d-flex pb-2 border-bottom mb-3 w-100">
                                        <span><i className="fas fa-desktop me-2 fs-2"></i></span>
                                        <h4 className="fw-bold mb-0">Web App</h4>
                                    </div>
                                    {/*<p>0 Designer Days (0 Weeks)</p>*/}
                                    {/*<p>0 Developer Days (0 Weeks)</p>*/}
                                    <h1>${weAppRate}</h1>
                                </div>
                                :
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <span><i className="fas fa-desktop me-2 fs-2"></i></span>
                                    <h2 className="fw-bold mt-3 mb-0">Need a Web App?</h2>
                                </div>
                            }
                        </Col>
                        <Col md={4}
                             className="text-white bg-ios py-5 cursor"
                             onClick={iosForm}>
                            {iosEstimate ?
                                <div
                                    className="d-flex flex-column align-items-start justify-content-center px-3 w-100">
                                    <div className="d-flex pb-2 border-bottom mb-3 w-100">
                                        <span><i className="fab fa-apple me-2 fs-2"></i></span>
                                        <h4 className="fw-bold mb-0">iOS App</h4>
                                    </div>
                                    {/*<p>0 Designer Days (0 Weeks)</p>*/}
                                    {/*<p>0 Developer Days (0 Weeks)</p>*/}
                                    <h1>${iosAppRate}</h1>
                                </div>
                                :
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <span><i className="fab fa-apple me-2 fs-2"></i></span>
                                    <h2 className="fw-bold mt-3 mb-0">Need a iOS App?</h2>
                                </div>
                            }
                        </Col>
                        <Col md={4}
                             className="text-white bg-android py-5 cursor"
                             onClick={androidForm}>
                            {androidEstimate ?
                                <div
                                    className="d-flex flex-column align-items-start justify-content-center px-3 w-100">
                                    <div className="d-flex pb-2 border-bottom mb-3 w-100">
                                        <span><i className="fab fa-android me-2 fs-2"></i></span>
                                        <h4 className="fw-bold mb-0">Android App</h4>
                                    </div>
                                    {/*<p>0 Designer Days (0 Weeks)</p>*/}
                                    {/*<p>0 Developer Days (0 Weeks)</p>*/}
                                    <h1>${androidAppRate}</h1>
                                </div>
                                :
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <span><i className="fab fa-android me-2 fs-2"></i></span>
                                    <h2 className="fw-bold mt-3 mb-0">Need a Android App?</h2>
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
                <div className="p-5 text-dark bg-light">
                    <p className="cost-heading">Total Cost: ${estimate ? estimate : '0'}</p>
                    <p className="sub-heading">Based on HubexTech rates. To use your own rates, click Show
                        Calculations
                        above.</p>
                    <p className="disclaimer">Please note, all cost estimates are intended to be indicative of
                        development costs and timescales
                        only and are exclusive of all hosting costs, paid services or purchased assets of any kind.
                        All
                        prices are in USD and inclusive of sales tax.
                    </p>
                </div>
            </div>
            <div className="container p-5 my-5 shadow-main bg-light">
                <p className="fs-5 fw-bold">Save your estimate so you can share with your team</p>
                <input
                    type="email"
                    placeholder="Enter Email"
                    className="form-control"
                    name="email"
                    onChange={getInTouchHandler}
                />
                <button className="btn btn-success mt-4" disabled={isDisabled} onClick={estimationSubmitHandler}>
                    Save Estimate
                </button>
            </div>
            <footer className=" bg-white shadow-main py-2 w-100">
                <div className="container d-flex align-items-center justify-content-center w-100">
                    <h6 className="my-3 fw-bold">
                        2022 &copy;
                        <a href="https://www.hubextech.com/" className="mx-2 copyright-main">
                            HubexTech
                        </a>
                        - All Rights Reserved.
                    </h6>
                </div>
            </footer>
        </section>
    )
        ;
}

export default Estimate;