import React from 'react';
import "./Card.css"
import {Container} from "react-bootstrap";


const Card = () => {
    return (
        <Container className="container">
            <div className="Card-main-content text-center d-flex flex-column align-items-center w-100">
                <div className="d-flex justify-content-center">
                    <p className="heading-text fw-bolder fs-2">
                        Hire dedicated ROR developers!
                    </p>
                </div>
                <p className="my-3 fs-5 p-0 px-md-5">
                    You are looking at a platform that provides incomparable Ruby on Rails
                    development services so that you may bring your brand identity's ambitions to
                    life. For more than a decade, Hubextech has been working toward aspirations.
                    And have worked for numerous brands to stand out in the market in the list of
                    top-notch. Ruby on Rails is the major league for software development because
                    of its word of mouth.
                </p>
                <p className="fs-5 py-3">
                    You may not want to miss this opportunity.
                </p>
                <div className="d-flex justify-content-center">
                    <a href="https://calendly.com/moeez-haider-hubextech/hubextech">
                        <button className="book-slot fw-bold">
                            Book your time Slot
                        </button>
                    </a>
                </div>
            </div>
        </Container>
    )
}


export default Card