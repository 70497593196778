import React from "react";
import { Container } from "react-bootstrap";
import "./ResumeFooter.css"

function ResumeFooter() {
    return (
        <footer className="footer-sideMenu w-100 text-white">
            <Container>
                <div className='copyright'>
                    <p className="pt-3">
                       2023 &copy; 
                        <a href="https://www.hubextech.com/" className="text-decoration-none ms-2">
                            HubexTech&nbsp;
                        </a>
                    </p>
                </div>
            </Container>
        </footer>
    );
}

export default ResumeFooter;
