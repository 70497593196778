import React from "react"
import "./Steps.css"
import ScheduleCall from "../../../../Assets/Images/Schedule.png";
import InterviewDevs from "../../../../Assets/Images/Interview.png";
import Contract from "../../../../Assets/Images/Contract.png";
import SelectionDevs from "../../../../Assets/Images/Selection.png";
import FreeTrail from "../../../../Assets/Images/FreeTrial.png";
import arrow from "../../../../Assets/Images/arrowRight.png"
import arrowDown from "../../../../Assets/Images/arrowDown.png"
import {Container} from "react-bootstrap";


const Steps = () => {
    return (
        <div className="d-flex flex-column align-items-center pb-5 text-center Steps-main-content w-100">
            <Container className="container">
                <div className="d-flex justify-content-center">
                    <p className="process_heading fs-2 fw-bolder">
                        A simple, quick, and reliable process for onboarding our Global talent: </p>
                </div>
                <div className="row mt-5 justify-content-center">
                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                        <div className="step-content-section">
                            <img src={ScheduleCall} alt="ScheduleCall" className="w-50"/>
                            <p className="fs-5 my-2">
                                Requirement gathering call
                            </p>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex align-items-center justify-content-center">
                        <img className="step-content-section-arrow d-none d-md-block mx-4" src={arrow} alt="arrow"
                             width="50"/>
                        <img className="d-block d-md-none mx-4" src={arrowDown} alt="arrow" width="50"/>
                    </div>
                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                        <div className="step-content-section">
                            <img src={SelectionDevs} alt="SelectionDevs" className="w-50"/>
                            <p className="fs-5 my-2">
                                Select the best out of the best from us
                            </p>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex align-items-center justify-content-center">
                        <img className="step-content-section-arrow d-none d-md-block mx-4" src={arrow} alt="arrow"
                             width="50"/>
                        <img className="d-block d-md-none mx-4" src={arrowDown} alt="arrow" width="50"/>
                    </div>
                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                        <div className="step-content-section">
                            <img src={InterviewDevs} alt="InterviewDevs" className="w-50"/>
                            <p className="fs-5 my-2">
                                Interview the shortlisted developer </p>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex align-items-center justify-content-center">
                        <img className="step-content-section-arrow d-none d-md-block mx-4" src={arrow} alt="arrow"
                             width="50"/>
                        <img className="d-block d-md-none mx-4" src={arrowDown} alt="arrow" width="50"/>
                    </div>
                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                        <div className="step-content-section">
                            <img src={FreeTrail} alt="FreeTrail" className="w-50"/>
                            <p className="fs-5 my-2">
                                Experience a free trial of 40 hours
                            </p>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex align-items-center justify-content-center">
                        <img className="step-content-section-arrow d-none d-md-block mx-4" src={arrow} alt="arrow"
                             width="50"/>
                        <img className="d-block d-md-none mx-4" src={arrowDown} alt="arrow" width="50"/>
                    </div>
                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                        <div className="step-content-section">
                            <img src={Contract} alt="Contract" className="w-50"/>
                            <p className="fs-5 my-2">
                                Hire developer on official contract
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}


export default Steps