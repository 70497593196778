import React from 'react';
import './App.css';
import {Routes, Route} from 'react-router-dom';
import HomePage from './Components/HomePage/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/js/brands'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/fontawesome'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './Components/TermsAndConditions/TermsAndConditions';
import Resumes from './Components/Resumes/ResumesHomePage';
import ErrorPage from './Components/UI/ErrorPage';
import CaseStudy from './Components/CaseStudy/CaseStudy';
import Services from "./Components/HomePage/SubSections/Services/Services";
import Estimate from "./Components/Estimation/Estimate";
import HUBEX_APP_ROUTES from './Components/Services/AppRoutes';
import ThankYouPage from "./Components/UI/ThankYouPage";

const App = () => {

    return (
        <Routes>
            <Route path={HUBEX_APP_ROUTES.Error_PAGE} element={<ErrorPage/>}></Route>
            <Route exact path={HUBEX_APP_ROUTES.HOME_PAGE} element={<HomePage/>}></Route>
            <Route exact path={HUBEX_APP_ROUTES.PRIVACY_POLICY_PAGE} element={<PrivacyPolicy/>}></Route>
            <Route exact path={HUBEX_APP_ROUTES.TERMS_AND_CONDITIONS_PAGE} element={<TermsAndConditions/>}></Route>
            <Route exact path={HUBEX_APP_ROUTES.CASE_STUDY_PAGE} element={<CaseStudy/>}></Route>
            <Route exact path={HUBEX_APP_ROUTES.RESUMES_PAGE} element={<Resumes/>}></Route>
            <Route exact path={HUBEX_APP_ROUTES.RUBY_ON_RAILS} element={<Services/>}></Route>
            <Route exact path={HUBEX_APP_ROUTES.ESTIMATION_MODULE} element={<Estimate/>}></Route>
            <Route exact path={HUBEX_APP_ROUTES.THANKYOU_PAGE} element={<ThankYouPage/>}></Route>
        </Routes>
    );
}
export default App;