import React from 'react';
import "./WhyChooseUs.css"
import {Col, Container, Row} from "react-bootstrap";

const WhyChooseUs = () => {
    return (
        <div className="d-flex flex-column align-items-center pb-5 mt-5 mx-2">
            <p className="my-5 h1">
                Why Choose Us?
            </p>
            <div className="d-flex justify-content-center">
                <p className="w-100 mx-md-5 px-md-0 fs-5 text-center">
                    We are a clan of Ruby on Rails Devs that take the challenge of creating brand
                    identities to stand out in the crowd of trends.
                    Our Company has been around for over a decade, and we have always been the
                    best at what we do and strive to establish partnerships with clients to achieve a
                    common goal. We still have some of our clients with us from the start for the
                    reasons:
                </p>
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}
                         className="Process-card-1 p-4 m-2 mb-4">
                        <p className="text-white h2 text-center">
                            Our clientele relationship
                        </p>
                        <p className="text-white h5 text-center">
                            We not only provide the best software services but also maintain a supportive
                            relationship with our partners.
                        </p>
                    </Col>
                    <Col md={6}
                         className="Process-card-1 p-4 m-2 mb-4">
                        <p className="text-white h2 text-center">
                            Attention to detail
                        </p>
                        <p className="text-white h5 text-center">
                            We build exactly what is required with satisfactory results and give life to a single
                            detail with our processes defined to keep an eye on the requirements provided
                            by clients.
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={6}
                         className="Process-card-1 p-4 m-2 mb-4">
                        <p className="text-white h2 text-center">
                            Experts only
                        </p>
                        <p className="text-white h5 text-center">
                            We have quality-oriented vetted engineers that are multifunctional highly
                            professional in their fields.
                        </p>
                    </Col>
                    <Col md={6}
                         className="Process-card-1 p-4 m-2 mb-4">
                        <p className="text-white h2 text-center">
                            Cost-efficient
                        </p>
                        <p className="text-white h5 text-center">
                            We have an in-house team of developers from the Asian region that is vastly
                            experienced and budget-friendly.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default WhyChooseUs