import React from 'react'
import { Container } from 'react-bootstrap';
import './SkillsResume.css'

const SkillsResume = (props) => {

    const skillsRespData = props.SkillRespData.technologies.data;

    return (
        <section className='resume-main card-Hero card-main resume-section-bg'>
            <Container>
                <div className='section-title'>
                    <h2>Skills</h2>
                </div>
                <div className='my-3 d-flex flex-wrap'>
                    {
                        skillsRespData.map((curr, index) => {
                            return <span className='s-title me-2 my-1' key={index}>{curr.title}</span>
                        })
                    }
                </div>
            </Container>
        </section>
    )
}
export default SkillsResume