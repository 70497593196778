import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import './PersonalResume.css';
import ExperienceContainer from './ExperienceContainer';
import ProjectContainer from './ProjectContainer';
import EducationContainer from './EducationContainer';

const PersonalResume = (props) => {

    const prRespData = props.PrRespData;

    return (
        <section id='resume-nav' className='resume-main card-Hero card-main sideMenu-links-section'>
            <Container>
                <div className='section-title'>
                    <h2>Resume</h2>
                </div>
                {/* Summary and Education Row */}
                <Row className='mb-3'>
                    <Col md={6}>
                        <h3 className='fs-3 fw-bold py-3'>Sumary</h3>
                        <div className='resume-border'>
                            <span className='summary-title fs-5 fw-bold'>{prRespData.name}</span>
                            <p>{prRespData.description}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <h3 className='fs-3 fw-bold py-3'>Education</h3>
                        {
                            prRespData.employee_educations.data.map((curr, index) => {
                                return <EducationContainer key={index} title={curr.title} start={curr.start_date} end={curr.end_date} desc={curr.description} institute={curr.institution} />
                            })
                        }
                    </Col>
                </Row>

                {/* Experience Row */}
                <Row className='mb-3'>
                    <span className='fs-3 fw-bold py-5'>Professional Experience</span>
                    {
                        prRespData.experiences.data.map((curr, index) => {
                            return <ExperienceContainer key={index} title={curr.title} start={curr.start_date} end={curr.end_date} descHTML={curr.content} company={curr.company_name} />
                        })
                    }
                </Row>
                <Row className='mb-3'>
                    <span className='fs-3 fw-bold py-5'>Projects</span>
                    {
                        prRespData.employee_projects.data.map((curr, index) => {
                            return <ProjectContainer key={index} title={curr.name} url={curr.url} descHTML={curr.description} />
                        })
                    }
                </Row>
            </Container>
        </section>
    )
}
export default PersonalResume
