import React from 'react';
import {Col, Row} from "react-bootstrap";

const ContactForm = () => {
    return (
        <Row className="m-0 p-0 w-100">
            <Col xl={6} className="d-flex flex-column justify-content-center align-items-center text-center">
                <h1 className="my-3 fw-bold">
                    Contact Us
                </h1>
                <p className="fs-4 px-2 px-md-5">
                    If you are looking for a collaboration to get your software development needs to
                    be fulfilled by a simple hiring process, HubexTech is the best place to get on
                    board with.
                </p>
            </Col>
            <Col xl={6} className="m-0 p-0">
                <iframe
                    src="https://finalrorhubextech.gr8.com/"
                    className="w-100"
                    scrolling="no"
                    title="Contact Us Form"
                    height="605"
                ></iframe>
            </Col>
        </Row>
    )
}


export default ContactForm