import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import './Projects.css';
import ProjectItem from "./ProjectItem";
import Slider from "react-slick";
import axios from "axios";
import HUBEX_APP_API from '../../Services/API'


const Projects = () => {


    //Project API ----------start-------------

    // API Response Data
    const [projData, setProjData] = useState('');

    useEffect(() => {
        const fetchData = () => {
            const baseURL = `${process.env.REACT_APP_BASE_URL}${HUBEX_APP_API.PROJECTS_API}`;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.REACT_APP_AUTH_KEY;
            axios.get(baseURL)
                .then(resp => {
                    setProjData(resp.data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        fetchData();
    },[]);
    if (!projData) return null;

    //Project API ----------end-------------

    // Slick Carousel setting---start
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                    dots: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    return (
        <>

            <section className="projects-main navlinks-section " id='projNav-main'>
                <div className="d-none d-xl-block">
                    <Row className="m-0">
                        <Col md={6} className="d-flex justify-content-end">
                            <div className="px-5">
                                <div className="mt-5">
                                    <span className="heading-bold ourProj-heading display-5">Our Projects</span>
                                    <p className="fs-5 ourProj-heading text-secondary">From Concept to Creation.</p>
                                </div>
                                <ul className="w-100 pe-5 list-unstyled">
                                    {projData.map((curr, index) => {
                                        return <ProjectItem key={index} id={curr.id} title={curr.title} ProjDesc={curr.description} Techs={curr.technologies.data} ProjImage={`${process.env.REACT_APP_BASE_URL}${curr.project_images.data[0].url}`} webLink={curr.website} caseStudy={curr.case_study.data} />
                                    })}
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} className="stky-col p-0 position-sticky top-0 pd-main">
                            <div>
                                <div className="position-relative d-flex justify-content-end ">
                                    {
                                        projData.map((curr, index) => {
                                            return <div key={index} className="sticky_pImages position-absolute pd-image w-100" style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${curr.project_images.data[0].url})`, transform: "translateX(100%)" }}></div>
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* Ipad, Mobile Responsiveness */}
                <Container className="d-block d-xl-none pt-5 pb-5">
                    <div className="text-center">
                        <span className="fw-bolder text-center display-5 mt-4">Our Projects</span>
                        <p className="fs-5 text-center text-secondary">From Concept to Creation.</p>
                    </div>
                    <ul className="list-unstyled">
                        <Slider {...settings}>
                            {
                                projData.map((curr, index) => {
                                    return <ProjectItem key={index} id={curr.id} title={curr.title} ProjDesc={curr.description} Techs={curr.technologies.data} ProjImage={`${process.env.REACT_APP_BASE_URL}${curr.project_images.data[0].url}`} webLink={curr.website} caseStudy={curr.case_study.data} />
                                })
                            }
                        </Slider>
                    </ul>
                </Container>
            </section>
        </>
    );
}

export default Projects
