import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import placeHolder from '../../../Assets/Images/dummy.png';
import './AboutResume.css';

const AboutResume = (props) => {

    const aboutRespData = props.AboutRespData;

    //When the Enity Image is not present we call the following statement to replace the empty space with a dumy image.
    const [replaceAbleImage, setReplaceAbleImage] = useState('');
    const onErrorHandler = () => {
        if (aboutRespData.image.data) {
            setReplaceAbleImage(`${process.env.REACT_APP_BASE_URL}${aboutRespData.image.data.url}`);
        } else {
            setReplaceAbleImage(placeHolder);
        }
    }
    !(replaceAbleImage) && onErrorHandler();

    return (
        <section id='resume-about' className='resume-main card-Hero card-main sideMenu-links-section'>
            <Container>
                <div className='section-title'>
                    <h2>About</h2>
                    <p>
                        {aboutRespData.details}
                    </p>
                </div>
                <Row className='d-flex justify-content-md-between'>
                    <Col md={6} lg={4} className='mb-4 d-flex justify-content-start'>
                        <div className='profile-img-main' style={{ backgroundImage: `url(${replaceAbleImage})` }}></div>
                    </Col>
                    <Col md={6} lg={8} className='text-black'>
                        <h3 className='fw-bold'>
                            {aboutRespData.title}
                        </h3>
                        <p>
                            {aboutRespData.description}
                        </p>
                        <Row>
                            <Col>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th className='py-3 d-flex align-items-center'>
                                                <i className='fas fa-chevron-right mx-1'></i>
                                                <strong> Degree: </strong>
                                            </th>
                                            <td className='py-3'>
                                                <div className='mx-1'>{aboutRespData.degree}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className='py-3 d-flex align-items-center'>
                                                <i className='fas fa-chevron-right mx-1'></i>
                                                <strong> City: </strong>
                                            </th>
                                            <td className='py-3'>
                                                <span className='mx-1'>{aboutRespData.city}</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
export default AboutResume;