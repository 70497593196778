import React from "react";
import './PorjectItem.css';
import InView from "react-intersection-observer";
import { Link } from "react-router-dom";

const ProjectItem = (props) => {

    const currentIntersectionHandler = (inView, entry) => {
        if (inView) {
            const imgSrc = entry.target.previousSibling.src;
            const imgSrcWithUrl = `url("${imgSrc}")`;
            const ele = document.querySelector('.stky-col')
            const eleImageList = ele.children[0].children[0].children;
            Array.from(eleImageList).forEach((curr) => {
                if (curr.style.backgroundImage === imgSrcWithUrl) {
                    curr.classList.add('translateImageView')
                }
                else {
                    curr.classList.remove('translateImageView')
                }
            })
        }
    }

    return (
        <li>
            <div className="proj-cont shadow">
                <img src={props.ProjImage} className="p-images d-block d-xl-none w-100" alt="pImage" />
                <InView onChange={(inView, entry) => currentIntersectionHandler(inView, entry)}>
                    <div className="p-3">
                        <a href={props.webLink} className="text-decoration-none text-black" target="_blank" rel="noreferrer"><h3 className=" pTitle">{props.title}</h3></a>
                        <div className="my-5 d-flex flex-wrap">
                            {
                                props.Techs.map((element, index) => {
                                    return <span className="proj-tech me-2 my-1" key={index}> {element.title} </span>
                                })
                            }
                        </div>
                        <div>
                            <p className="mt-5 mb-3 fw-lighter pcard-text ">{props.ProjDesc}</p>
                            {props.caseStudy &&
                                <div className="d-flex align-items-center">
                                    <Link to={`/casestudy/${props.title.toLowerCase().replace(/\s/g, '-')}/${props.id}`}><button className="btn-for text-dark fw-bold bg-transparent rounded-3"><span>Case study</span></button></Link>
                                </div>
                            }
                        </div>
                    </div>
                </InView>
            </div>
        </li>
    );
}
export default ProjectItem;