import React, { useEffect } from "react";
import "./PrivacyPolicy.css";
import HubexLogo from '../../Assets/Images/logo.png'

function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <section>
            <header>
                <nav className="est_navbar navbar navbar-expand-lg navbar-light bg-white shadow-sm-main">
                    <div className="container">
                        <div className="d-flex justify-content-between w-100">
                            <a className="navbar-brand" href="https://hubextech.com"><img src={HubexLogo} alt="hubextech" width="140px" /></a>
                        </div>
                    </div>
                </nav>
            </header>
            <div className="container">
                <h2 className="text-center fw-bold my-5"> Privacy Policy of HubexTech: </h2>
                <div className="pvivacypolicy_section">
                    <p className="my-4">
                        This Privacy Policy merely intends to update you of our
                        <b>rules and practices</b> regarding the <b>use and disclosure</b> of
                        personal information collected through the website. HubexTech is
                        highly concerned and responsible about keeping/maintaining
                        confidentiality of the data of, all the users. The home page of the
                        website is as follow:
                        <a
                            href="https://www.hubextech.com"
                            className="pvivacypolicy_link"
                        >
                            &nbsp;www.hubextech.com.
                        </a>
                    </p>
                    <p className="my-4">
                        Our software development services help to get the customer information
                        provided in the registration form so that *cookies can be placed on
                        the customer’s computer. Then they can avail more information about
                        customer activity which in turn helps to provide the desired
                        information about the product preferred by them in right time. This
                        helps the customer to buy the right product in less time and with more
                        ease.
                    </p>
                    <p className="my-4">
                        We do ask for the consent of the user to process the information
                        provided, while submitting the form and, data is processed only if the
                        user agrees.
                    </p>
                    <div>
                        <h3 className="my-4">
                            <b> The Information We Collect </b>
                        </h3>
                        <div>
                            <h4 className="my-4">
                                <b> Site Activity Data </b>
                            </h4>
                            <p className="my-4">
                                Our web server collects and logs some data every time a guest return
                                results in the current situation. These internal logs are kept for a
                                reasonable period of time. These logs, however, are not limited to
                                your machine's TCP / IP address, your username (if appropriate), date,
                                time, and documents.
                            </p>
                        </div>
                        <div>
                            <h4 className="my-4">
                                <b> *Cookies </b>
                            </h4>
                            <p className="my-4">
                                Parts of the site may develop cookies just for security and
                                authentication purposes. This data is operated exclusively to keep up
                                your computer’s session to the server. This data is not and will not
                                be shared or sold to outsider associations for any reason.
                            </p>
                        </div>
                        <div>
                            <h4 className="my-4">
                                <b> Personal Information </b>
                            </h4>
                            <p className="my-4">
                                Individual data that may include the following; name, individual
                                postal and email address, or individual phone number is collected just
                                when you gladly share it with us. Such data is received when the user
                                send an email through the website or reach. After being in the
                                contract with HubexTech all the user’s valuable information will be
                                kept confidential.
                            </p>
                            <p className="my-4">
                                The personal information given to the association will be sent to the
                                individual or division prepared to deal with user’s demand and is
                                utilized just to react to the request.
                            </p>
                            <p className="my-4">
                                As a web design & development company, HubexTech will not be selling
                                any of the personal information to any third parties.
                            </p>
                        </div>
                        <div>
                            <h4 className="my-4">
                                <b> Purpose /Usage of collected information </b>
                            </h4>
                            <p className="my-4">
                                We use the information that we collect for our internal review,
                                tracking and for contact. The information is then archived in our
                                server. We study the information that we collect to understand
                                user/visitor trends in order to improve our services, content, website
                                and products quality etc. and ultimately to improve user and customer
                                experience.
                            </p>
                            <p className="my-4">
                                The sole purpose of collecting data is to use it for understanding/
                                discussing user’s business requirements and provision of the
                                solutions; also, can be used to send periodic promotional,
                                non-promotional and other marketing related information and material
                                that would add to our business values.
                            </p>
                        </div>
                        <div>
                            <h4 className="my-4">
                                <b> Payment Information </b>
                            </h4>
                            <p className="my-4">
                                All the information related to the financial kept highly confidential
                                by our team. Details related to credit card (etc) is not being stored
                                anywhere and is rather securely being transferred to our payment
                                partners.
                            </p>
                            <p className="my-4">
                                We will use user’s contact details to send you invoice receipts,
                                reminders and other related transactional emails.
                            </p>
                        </div>
                        <div>
                            <h4 className="my-4">
                                <b> Security: </b>
                            </h4>
                            <p className="my-4">
                                The security of personal information is important to us. We maintain a
                                variety of appropriate technical and organizational safeguards to
                                protect your personal information. We limit access to personal
                                information about user to people who we believe reasonably need to
                                come into contact with that information to provide products or
                                services to the user or in order to do their jobs. Further, we have
                                implemented reasonable physical, electronic, and procedural safeguards
                                designed to protect personal information about our users.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <header>
                <nav className="est_navbar navbar navbar-expand-lg navbar-light bg-white shadow-sm-main mt-5">
                    <div className="container d-flex align-items-center justify-content-center w-100">
                        <h6 className="my-3 fw-bold">
                            2022 &copy;
                            <a href="https://www.hubextech.com/" className="mx-2 copyright-main">
                                HubexTech
                            </a>
                            - All Rights Reserved.
                        </h6>
                    </div>
                </nav>
            </header>
        </section>
    );
}

export default PrivacyPolicy;
