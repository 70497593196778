// Hubex app API as constant
const HUBEX_APP_API = {
    // Projects API as constant
    PROJECTS_API: '/api/projects?populate=*',
    // Resumes API route as constant
    EMPLOYEES_API: '/api/employees?filters[slug]=',
    // Populate route as constant
    POPULATE_ALL: '&populate=*',
    // Resumes API route as constant
    CASE_STUDY_API: '/api/case-studies?filters[project]=',
    // Populate route as constant
    LEADS: '/api/leads',
    
}
export default HUBEX_APP_API